<script>
export default {
	lang: 'account',
	metaInfo() {
		return {
			title: this.cTitle,
		}
	},
	props: {
		title: String,
	},
	data() {
		return {
			items: [
				{ title: this.$lang('Mi cuenta'), icon: 'mdi-account', attrs: { to: { name: 'user.account' } } },
				{
					title: this.$lang('Mis compras'),
					icon: 'mdi-shopping',
					attrs: { to: { name: 'user.orders' } },
				},
				{
					title: this.$lang('Mis favoritos'),
					icon: 'mdi-heart',
					attrs: { to: { name: 'user.favorites' } },
				},
				{
					title: this.$lang('Mis Reseñas'),
					icon: 'mdi-star',
					attrs: { to: { name: 'user.reviews' } },
					showIf: () => {
						const reviewsConfig = this.$srv('reviewsConfig')
						return reviewsConfig && reviewsConfig.enabled
					},
				},
			],
		}
	},
	computed: {
		cTitle() {
			return this.title || this.items.find((x) => x.attrs?.to?.name == this.$route.name)?.title || ''
		},
	},
}
</script>

<template>
	<v-container class="mb-16">
		<v-row>
			<v-col cols="12" md="3" class="px-0 px-sm-6 pt-sm-6">
				<div class="d-flex flex-row flex-wrap flex-sm-column">
					<v-tabs :vertical="$b.td" :centered="$b.m" :show-arrows="$b.m" optional>
						<template v-for="item in items">
							<v-tab
								:key="item.title"
								v-bind="item.attrs"
								v-on="item.listeners"
								v-if="!item.showIf || item.showIf()"
							>
								<v-icon class="mr-1 mr-md-8">{{ item.icon }}</v-icon>
								<div class="text-no-wrap">{{ item.title }}</div>
							</v-tab>
						</template>
					</v-tabs>
				</div>
			</v-col>
			<v-col cols="12" md="9" class="pt-5">
				<div class="text-h4 mb-4">
					{{ cTitle }}
				</div>
				<v-divider class="mb-4"></v-divider>
				<slot> </slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped lang="scss">
::v-deep .v-tab {
	justify-content: start !important;
}
</style>
