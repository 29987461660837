<script>
import { get } from 'vuex-pathify'
export default {
	lang: 'account',
	computed: {
		userData: get('shop/user'),
	},
	trackPageView: true,
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		logout() {
			this.$shopApi.post({
				url: '/user/logout',
				loading: (v) => (this.loading = v),
				onSuccess: ({ options }) => {
					options.abort()
					window.location.href = this.$router.resolve({ name: 'home' }).href
				},
			})
		},
	},
}
</script>

<template>
	<UserpanelLayout>
		<v-row>
			<v-col cols="12">
				<CardLayout :title="$lang('Datos de contacto')" :showIcon="false">
					<ContactData />
				</CardLayout>
			</v-col>
			<v-col cols="12" md="6">
				<CardLayout :title="$lang('Acceso')" :showIcon="false" class="h100">
					<UserData />
				</CardLayout>
			</v-col>
			<v-col cols="12" md="6">
				<CardLayout :title="$lang('Cambiar contraseña')" :showIcon="false" class="h100">
					<ChangePassword />
				</CardLayout>
			</v-col>
			<v-col cols="12">
				<Button :class="{ w100: $b.m }" @click="logout" :color="$vars.error" :loading="loading">
					<v-icon>mdi-account-off-outline</v-icon>{{ 'Salir' | lang }}
				</Button>
			</v-col>
		</v-row>
	</UserpanelLayout>
</template>
